import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
export const Analytics = () => {
  return (
    <>
      <Header />
      <section className="sm:mt-6  max-w-7xl mx-auto px-4 ">
        <div className="my-4  grid lg:grid-cols-2 grid-cols-1 lg:gap-4 items-center">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 ">
              <span className="block xl:inline"> Analytics</span>
              {/* <span className="block text-indigo-600 xl:inline">
                online business
              </span> */}
            </h1>
            <p className="mt-3 text-base text-gray-800  text-justify  sm:max-w-xl sm:mx-auto  lg:mx-0">
            At Way4franchise, we believe that understanding your business
                  performance is the key to making smart decisions. That’s why
                  we offer detailed analytics services to help you track and
                  measure the success of your franchise. With the right data,
                  you can make better choices and improve your marketing
                  efforts, website performance, and customer engagement. Our
                  analytics services provide clear insights into how your
                  website and marketing campaigns are performing. We help you
                  gather and understand data so you can take action and grow
                  your franchise.
            </p>
            {/* Button Section */}
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 "
                >
                  Get started
                </a>
              </div>
              
            </div>
            {/* End of Button Section */}
          </div>
          {/*   Image Section     */}
          <div className="lg:inset-y-0 lg:right-0  my-4">
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="https://t3.ftcdn.net/jpg/03/54/00/74/360_F_354007466_mm4QilA3n92YWPseqs82gbWxbb06R1i4.jpg"
              alt
            />
          </div>
          {/*   End of Image Section     */}
        </div>
      </section>
      <div className=" dark:bg-gray-800 border-y">
        <div className="items-center max-w-7xl px-4 py-8 lg:mx-auto mx-2 lg:grid lg:grid-cols-4 lg:gap-16">
          <div className="col-span-2 mb-8">
            <p className="text-sm font-medium text-purple-500 dark:text-purple-500">
              Our analytics
            </p>
            <h2 className="mt-1 mb-1 text-2xl font-semibold tracking-tight text-gray-900  dark:text-white">
              Our analytics services include
            </h2>
            <div className="pt-4 mt-4 space-y-4 border-t border-gray-200 dark:border-gray-700">
              <div>
                <div className="text-gray-800">
                  <h3 className="font-semibold ">
                    Marketing Campaign Insights
                  </h3>
                  <p className="text-[14px] ">
                    Get detailed reports on how your marketing campaigns are
                    performing. We track things like
                  </p>
                </div>
                <ul className="px-10 pb-3 text-sm list-disc text-gray-800">
                  <li>How many people see your ads.</li>
                  <li>How many people click on your ads.</li>
                  <li>
                    How many leads or customers you get from your campaigns.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-span-2 space-y-6 md:grid md:grid-cols-2 md:gap-8 md:space-y-0">
            <div>
              <h3 className="mb-2 text-base font-semibold ">
                Website Traffic Analysis
              </h3>
              <p className="text-sm text-gray-800 text-justify">
                We track how many people visit your website, where they come
                from, and what they do while they’re there. This helps you
                understand your audience and improve their experience.
              </p>
            </div>
            <div>
              <h3 className="mb-2 text-base font-semibold text-gray-800">
                Conversion Tracking
              </h3>
              <p className="text-sm text-gray-800 text-justify">
                Our team helps you see how well your website turns visitors into
                leads or franchise partners. We identify the parts of your
                website that need improvement to increase conversions.
              </p>
            </div>
            <div>
              <h3 className="mb-2 text-base font-semibold dark:text-white">
                Franchisee Engagement Metrics
              </h3>
              <p className="text-sm text-gray-800 text-justify">
                We track how potential franchisees are interacting with your
                brand. This includes how often they visit your website, engage
                with your content, or respond to your marketing campaigns.
              </p>
            </div>
            <div>
              <h3 className="mb-2 text-base font-semibold dark:text-white">
                Real-Time Reporting
              </h3>
              <p className="text-sm text-gray-800 text-justify ">
                With real-time reports, you’ll always have up-to-date
                information about how your website and marketing campaigns are
                performing. This allows you to make quick decisions and adapt
                your strategy as needed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
