import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
const Insights = () => {
  return (
    <>
      <Header />

      <section className="sm:mt-6  max-w-7xl mx-auto px-4 ">
        <div className="my-4  grid lg:grid-cols-2 grid-cols-1 lg:gap-4 items-center">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 ">
              <span className="block xl:inline">   Insights</span>
              {/* <span className="block text-indigo-600 xl:inline">
                online business
              </span> */}
            </h1>
            <p className="mt-3 text-base text-gray-800  text-justify  sm:max-w-xl sm:mx-auto  lg:mx-0">
            At Way4franchise, we believe that having the right insights is
                  essential for making informed decisions about your franchise.
                  Our Insights services help you understand key data and trends
                  in your business, allowing you to optimize your strategies and
                  improve performance. We provide you with valuable information
                  that can guide your marketing efforts, customer engagement,
                  and overall business growth.
            </p>
            {/* Button Section */}
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 "
                >
                  Get started
                </a>
              </div>
            </div>
            {/* End of Button Section */}
          </div>
          {/*   Image Section     */}
          <div className="lg:inset-y-0 lg:right-0  my-4">
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
               src="https://audienceplay.com/wp-content/uploads/2023/08/people-analytics.jpg"
              alt="About Us Image"
            />
          </div>
          {/*   End of Image Section     */}
        </div>
      </section>

      <div className="w-7xl lg:mx-10 mx-2 mt-16 px-2">
        <div className="">
          <p className="mb-6 lg:text-3xl sm:text-xl text-lg font-semibold">
            Our insights services include
          </p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Data Analysis
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We analyze data from your website, marketing campaigns, and sales
              to uncover important trends and patterns. This helps you
              understand what is working well and what needs improvement.
            </p>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Customer Insights
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              By studying customer behavior and preferences, we help you
              understand your audience better. This includes
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Demographic Information :-{" "}
                </span>{" "}
                Who your customers are (age, location, interests).
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  {" "}
                  Buying Habits :-{" "}
                </span>{" "}
                What products or services they prefer and how often they.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Market Trends
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We keep you updated on the latest trends in the franchising
              industry, helping you stay competitive. This includes insights
              into:
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Emerging Markets :-{" "}
                </span>{" "}
                Areas with growing interest in franchising.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Consumer Preferences :-{" "}
                </span>{" "}
                What customers are looking for in a franchise.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Performance Metrics
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We track important metrics to measure the success of your
              franchise, including
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Sales Growth :-{" "}
                </span>{" "}
                How your sales are increasing over time.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Customer Retention :-{" "}
                </span>{" "}
                How many customers return for repeat business.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  {" "}
                  Marketing Effectivenes :-{" "}
                </span>{" "}
                How well your marketing strategies are performing.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Competitive Analysis
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We help you understand your competition by analyzing their
              strengths and weaknesses. This allows you to
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Identify Opportunities :-{" "}
                </span>{" "}
                Find gaps in the market that your franchise can fill.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  Refine Your Strategy :-{" "}
                </span>{" "}
                Make adjustments to your approach based on competitor
                activities.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Insights;
