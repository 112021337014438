import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
const Marketing = () => {
  return (
    <>
      <Header />
     


      <section className="sm:mt-6  max-w-7xl mx-auto px-4 ">
        <div className="my-4  grid lg:grid-cols-2 grid-cols-1 lg:gap-4 items-center">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 ">
              <span className="block xl:inline">  Marketing</span>
              {/* <span className="block text-indigo-600 xl:inline">
                online business
              </span> */}
            </h1>
            <p className="mt-3 text-base text-gray-800  text-justify  sm:max-w-xl sm:mx-auto  lg:mx-0">
            At Way4franchise, we believe that effective marketing is the
                  key to unlocking business growth and success. We offer a
                  comprehensive suite of IT services that are designed to
                  enhance your franchise’s digital presence, attract potential
                  franchisees, and help your business thrive in the competitive
                  market. We specialize in website design and digital marketing
                  solutions, ensuring that your brand not only looks great
                  online but also performs exceptionally. Whether you’re
                  starting fresh or looking to revamp your existing online
                  presence, we have the tools and expertise to drive results.
            </p>
            {/* Button Section */}
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 "
                >
                  Get started
                </a>
              </div>
            </div>
            {/* End of Button Section */}
          </div>
          {/*   Image Section     */}
          <div className="lg:inset-y-0 lg:right-0  my-4">
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="https://online.hbs.edu/Style%20Library/api/resize.aspx?imgpath=/PublishingImages/blog/posts/digital-marketing-skills.jpg&w=1200&h=630"
              alt="About Us Image"
            />
          </div>
          {/*   End of Image Section     */}
        </div>
      </section>


      <div className="w-7xl lg:mx-10 mx-2 mt-16 px-2 mb-8 ">
        <div className="">
          <p className="mb-6 lg:text-3xl sm:text-xl text-lg font-semibold">
            Our marketing solutions include
          </p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Custom Website Design and Development
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              A strong digital presence begins with a stunning, functional
              website. We create custom websites that are
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Visually Appealing :-{" "}
                </span>{" "}
                Engaging, modern designs that reflect your brand's identity and
                vision.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold"> Responsive :- </span>{" "}
                Mobile-friendly designs that work seamlessly across all devices.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  {" "}
                  User-Friendly :-{" "}
                </span>{" "}
                Easy navigation and functionality that provide a smooth user
                experience.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  {" "}
                  Optimized for Conversions :-{" "}
                </span>{" "}
                Websites designed to turn visitors into franchise leads.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Search Engine Optimization (SEO)
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              Get noticed by your target audience. Our SEO services include
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Keyword Research :-{" "}
                </span>{" "}
                Identifying the most relevant and profitable search terms for
                your franchise.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  {" "}
                  On-Page Optimization :-{" "}
                </span>{" "}
                Enhancing your website’s structure and content for improved
                search engine rankings.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold"> Local SEO :- </span>{" "}
                Ensuring your business ranks well for local franchise searches,
                reaching people where they are.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Content Marketing
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              Content is king, and we help you craft content that resonates with
              your audience. Our content marketing services cover
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">Blogging :- </span>{" "}
                Create informative, engaging articles to attract and educate
                potential franchisees.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Franchise Newsletters :-{" "}
                </span>{" "}
                Keep your subscribers informed with valuable franchise updates,
                offers, and news.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  {" "}
                  Franchise Brochures and Guides :-{" "}
                </span>{" "}
                Professionally designed marketing materials to present your
                franchise in the best light.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Social Media Marketing
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We help you leverage social media platforms to build relationships
              with potential franchisees. Our services include
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Social Media Strategy :-{" "}
                </span>{" "}
                Tailored social media plans to target your specific audience.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Paid Advertising :-{" "}
                </span>{" "}
                Running targeted ad campaigns on platforms like Facebook,
                Instagram, and LinkedIn to generate leads.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  {" "}
                  Brand Engagemen :-{" "}
                </span>{" "}
                Creating content that fosters engagement and strengthens your
                online community.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Analytics and Performance Tracking
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              Data-driven marketing is key to success. We provide detailed
              analytics so you can see what’s working and where improvements can
              be made. Our services include
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Website Analytics :-{" "}
                </span>{" "}
                Track traffic, user behavior, and conversion rates to optimize
                your site’s performance.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  Campaign Reporting :-{" "}
                </span>{" "}
                Get in-depth reports on the effectiveness of your marketing
                campaigns, from website visits to lead generation.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Franchise-Specific Marketing
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              Our marketing strategies are specifically tailored for franchises.
              We understand the unique challenges you face, and our solutions
              are designed to
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Boost Franchise Lead Generation :-{" "}
                </span>{" "}
                Help you connect with potential franchisees who are ready to
                invest.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Strengthen Your Brand's Online Presence :-{" "}
                </span>{" "}
                Build trust with your audience through consistent, professional
                online marketing.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  Increase Franchise Visibility :-{" "}
                </span>{" "}
                Ensure that your franchise opportunities stand out in a crowded
                marketplace.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-gray-900">
        <div className="px-6 py-16 sm:px-6  lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-xl font-bold mb-3 tracking-tight text-white sm:text-3xl">
              Why Choose Us?
            </h2>
            <p className="mx-auto  text-lg leading-8 text-gray-300 ">
              With Way4franchise’s marketing and IT services, you get more than
              just a service provider—you get a partner dedicated to your
              success. Our team is committed to helping you grow your franchise
              through smart, effective marketing solutions. Let us handle your
              digital marketing needs so you can focus on expanding your
              franchise business.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Marketing;
