import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";


import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Industry Leadership',
    description: 'Be at the forefront of the franchising industry in India.',
  },
  { name: 'Innovation', description: ' Contribute to cutting-edge solutions that shape the future of franchise networking.' },
  {
    name: 'Professional Growth',
    description: ' Develop your skills and advance your career in a supportive environment.',
  },
  {
    name: 'Work-Life Balance',
    description: 'Enjoy flexible work arrangements and a positive company culture.',
  },
  { name: 'Impactful Work', description: 'Help entrepreneurs realize their dreams of business ownership' },

]
const Career = () => {
  return (
    <>
      <Header />
       <section className="sm:mt-6  max-w-7xl mx-auto px-4 ">
        <div className="my-4  grid lg:grid-cols-2 grid-cols-1 lg:gap-4 items-center">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 ">
              <span className="block xl:inline"> Careers at Wayforfranchise</span>
              {/* <span className="block text-indigo-600 xl:inline">
                online business
              </span> */}
            </h1>
            <p className="mt-3 text-base text-gray-800  text-justify  sm:max-w-xl sm:mx-auto  lg:mx-0">
            Join our dynamic team and be part of the exciting world of
                franchising! At Wayforfranchise, we're passionate about
                connecting entrepreneurs with thriving franchise opportunities.
                We're always on the lookout for talented individuals who share
                our vision and can contribute to our growth
            </p>
            {/* Button Section */}
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 "
                >
                  Get started
                </a>
              </div>
              
            </div>
            {/* End of Button Section */}
          </div>
          {/*   Image Section     */}
          <div className="lg:inset-y-0 lg:right-0  my-4">
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="https://images.unsplash.com/photo-1531973576160-7125cd663d86"
                alt="About Us Image"
            />
          </div>
          {/*   End of Image Section     */}
        </div>
      </section>

    <div className="bg-white py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Work With Us
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why Work With Us?
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Develop your skills and advance your career in a supportive environment.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-4 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon aria-hidden="true" className="absolute left-0 top-1 h-5 w-5 text-indigo-500" />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
      <div className="w-7xl lg:mx-10 mx-2 mt-10 px-2">
        <div className="border shadow-md rounded-md ">
          <div className="border-b bg-gray-200 py-2">
            <h5 className="text-lg font-semibold  px-4 capitalize">
              {" "}
              Current Openings
            </h5>
          </div>
          <p className=" text-gray-600 px-6 text-justify pt-5">
            We're currently hiring for the following positions
          </p>
          <ul className="px-10 pb-2 list-disc ">
            <li className="py-1 text-justify">Franchise Development Manager</li>
            <li className="py-1 text-justify">Digital Marketing Specialist</li>
            <li className="py-1 text-justify">
              To upload or transmit viruses or any other type of malicious code.
            </li>
            <li className="py-1 text-justify">
              Content Writer (Franchising Industry)
            </li>
            <li className="py-1 text-justify">Web Developer</li>
            <li className="py-1 text-justify">
              Customer Success Representative
            </li>
          </ul>
          <p className="text-justify px-6 pb-4">
            For detailed job descriptions and to apply, please visit our main
            Careers page or send your resume to careers@wayforfranchise.com.
          </p>
        </div>

        <div className="border shadow-md rounded-md mt-5">
          <div className="border-b bg-gray-200 py-2">
            <h5 className="text-lg font-semibold  px-4 capitalize">
              {" "}
              How to Use the FDD
            </h5>
          </div>
          <ul className="px-10 pt-4 list-disc ">
            <li className="py-1 text-justify">
              Read it thoroughly and carefully
            </li>
            <li className="py-1 text-justify">
              Seek professional advice from a lawyer and accountant
            </li>
            <li className="py-1 text-justify">
              Ask questions about anything you don't understand
            </li>
            <li className="py-1 text-justify">
              Compare it with FDDs from other franchise opportunities you're
              considering
            </li>
            <li className="py-1 text-justify">
              Use it as a basis for further due diligence
            </li>
          </ul>
          <p className="py-1 text-justify px-6 pb-5">
            Remember, while the FDD provides valuable information, it should be
            just one part of your overall evaluation of a franchise opportunity.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Career;
